import Head from "next/head";
import { NextSeo } from "next-seo";

/** Forces indexing to be enabled on the current page. */
export function EnableIndexing() {
  return (
    <>
      <NextSeo noindex={false} nofollow={false} />
      <Head>
        {/* Workaround for https://github.com/garmeeh/next-seo/issues/967 */}
        <meta key="robots" name="robots" content="index,follow" />
      </Head>
    </>
  );
}
