import { Global, css } from "@emotion/react";
import NextImage from "next/image";
import {
  Box,
  Center,
  Flex,
  Heading,
  Link,
  Text,
  VisuallyHidden,
  Fade,
  SlideFade,
  useTheme,
} from "@chakra-ui/react";

import { EnableIndexing } from "@planity/seo";
import { BrandWordmark } from "@planity/ui";

import nycSunflareImage from "./nyc-sunflare.jpeg";

const IMAGE_BACKGROUND_COLOR_HEX = "#20222A";
const IMAGE_BACKGROUND_COLOR_ALPHA = "rgba(32,34,42, 0.8)";

export default function MarketingHomePage() {
  const theme = useTheme();

  return (
    <>
      <EnableIndexing />

      <Global
        styles={css`
          body {
            background: ${IMAGE_BACKGROUND_COLOR_HEX};
          }
        `}
      />

      <Flex position="relative" justifyContent="center" alignItems="center">
        <Box position="absolute" top={0} left={0}>
          <NextImage
            src={nycSunflareImage}
            placeholder="blur"
            style={{ width: "auto", height: "100vh", objectFit: "cover" }}
            sizes={`
              (max-width: ${theme.breakpoints.sm}) 442px,
              828px
            `}
            alt="A person standing on a bridge looking towards the sunset as the rays peek through skyscrapers upon a busy road in New York City"
            priority
          />

          {/* Gradient to seamlessly blend image into body background, */}
          <Box
            position="absolute"
            top={0}
            right={0}
            width="25%"
            height="100%"
            bgGradient={`linear(to-r, rgba(0, 0, 0, 0), ${IMAGE_BACKGROUND_COLOR_HEX})`}
          />

          <Box
            position="absolute"
            zIndex={2}
            left={0}
            bottom={4}
            width="100%"
            textAlign="center"
            color="gray.400"
          >
            <Link
              fontSize="sm"
              href="https://www.instagram.com/the.vantage.point/?ref=planityevents"
              isExternal
            >
              Photo by Lerone Pieters
            </Link>
          </Box>
        </Box>

        <Flex
          position="relative"
          zIndex={1}
          ml={{ lg: "25vw" }}
          height="100vh"
          direction="column"
          justifyContent="center"
          alignItems="center"
          color="gray.200"
        >
          <Box p={10}>
            <Heading
              as="h1"
              size={{ base: "xl", lg: "2xl" }}
              mb={{ base: 4, lg: 8 }}
              textAlign="center"
            >
              <Center mb={{ base: 8 }}>
                <Box maxWidth={{ base: "100%", lg: "300px" }}>
                  <Fade transition={{ enter: { duration: 2, delay: 0.25 } }} in>
                    <BrandWordmark size={320} />
                  </Fade>
                </Box>
                <VisuallyHidden>Planity</VisuallyHidden>
              </Center>

              <Box
                p={2}
                bg={{ base: IMAGE_BACKGROUND_COLOR_ALPHA, lg: "transparent" }}
                borderRadius="12px"
              >
                <Fade transition={{ enter: { duration: 1.5, delay: 2 } }} in>
                  <Heading size="2xl" as="h1" textTransform="lowercase">
                    Explore City Events
                  </Heading>
                </Fade>
              </Box>
            </Heading>

            <SlideFade transition={{ enter: { duration: 1.5, delay: 4 } }} in>
              <Text
                color={{ lg: "gray.400" }}
                fontSize={{ base: "xl", lg: "2xl" }}
                textAlign="center"
                p={4}
                bg={{ base: IMAGE_BACKGROUND_COLOR_ALPHA, lg: "transparent" }}
                borderRadius="12px"
                borderTopColor="whiteAlpha.400"
                borderTopWidth={1}
              >
                Coming soon to New York City.
              </Text>
            </SlideFade>
          </Box>
        </Flex>
      </Flex>
    </>
  );
}
